import { createStore } from 'vuex'

export default createStore({
  state: {
    login: '',
    password: '',
    auth: 0,
    // url: 'https://druzinaadmin.ru:85/',
    url: 'https://sk-nast-admin.ru:85/',
    async query(str) {
      const res = await fetch(str);
      const answ = (await res.json());
      return answ;
    },
    async queryPage(str) {
      const res = await fetch((`${this.url}sql?password=${this.password}&login=${this.login}&query=${encodeURIComponent(str)}`));
      const answ = (await res.json());
      return answ;
    },
    async userNotification(id, text) {
      await this.queryPage(`INSERT INTO notifications (id, textMessage) VALUES("${id}", "${text}")`);
      
    },
    path: 'F:\\OpenServer\\images\\'
  },
  getters: {
    
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
  }
})

